import ApiService from './api.service';

class TasksService {
  async customerTasks(projectId) {
    return await ApiService.get(`/projects/${projectId}/tasks/customer`);
  }

  async msbTasks(projectId) {
    return await ApiService.get(`/projects/${projectId}/tasks/msb`);
  }

  async createTask(params) {
    return await ApiService.post('/tasks', params);
  }

  async dropboxDocumentFiles(projectId) {
    return await ApiService.get(`/projects/${projectId}/tasks/dropbox_files`);
  }
  // async updateProject(id, params) {
  //   return await ApiService.postMultipart(`/projects/${id}/update`, params);
  // }

  async deleteTask(id) {
    return await ApiService.delete(`/tasks/${id}`);
  }

  async doneTask(id) {
    return await ApiService.post(`/tasks/${id}/done`);
  }

  async undoneTask(id) {
    return await ApiService.post(`/tasks/${id}/undone`);
  }

  // async archiveProject(id) {
  //   return await ApiService.post(`/projects/${id}/archive`);
  // }

  // async unarchiveProject(id) {
  //   return await ApiService.post(`/projects/${id}/unarchive`);
  // }

  // async projectDetail(id) {
  //   return await ApiService.get(`/projects/${id}`);
  // }

  // async projectDocumentsFilder(id, path) {
  //   return await ApiService.post(`/projects/${id}/folders`, { path: path });
  // }
}

export default new TasksService();
