<template>
  <TabView
    class="col-12"
    lazy
    id="task-tab"
    :activeIndex="tabActiveIndex"
    @tab-click="tabClick($event)"
    @tab-change="tabChange($event)"
  >
    <TabPanel header="Attività cliente">
      <Toolbar class="pl-0 pr-0">
        <template #end>
          <Button
            label="Aggiungi attività"
            class="p-button-rounded p-button-sm"
            icon="pi pi-plus"
            @click="openCustomerForm()"
          />
        </template>
      </Toolbar>

      <h4 class="col-12 text-center mt-5" v-if="customerTasks.length == 0">
        Nessuna attività
      </h4>

      <DataTable :value="customerTasks" v-else>
        <Column bodyClass="timeline">
          <template #body="row">
            <template v-if="row.data.typology == 'milestone'">
              <i
                class="pi pi-star-fill"
                v-if="row.data.done"
                @click="taskUndone(row.data)"
              />
              <i class="pi pi-star" v-else @click="taskDone(row.data)" />
            </template>
            <template v-else>
              <i class="pi pi-check-circle" v-if="row.data.done" />
              <i class="pi pi-circle" v-else />
            </template>
          </template>
        </Column>
        <Column
          field="due_date"
          header="Data Consegna"
          class="task-due-date-column"
        >
          <template #body="row">
            {{
              row.data.due_date !== null
                ? this.$moment(row.data.dueDate).format("DD/MM/YYYY")
                : "-"
            }}
          </template>
        </Column>
        <Column
          field="title"
          header="Titolo"
          class="task-title-column"
        ></Column>

        <Column field="note" header="Descrizione" class="task-note-column">
          <template #body="row">
            <i
              class="pi pi-comment"
              v-if="row.data.note !== null && row.data.note !== ''"
              v-tooltip.top="`${row.data.note}`"
            />
          </template>
        </Column>

        <Column field="document" header="Documento" class="task-file-column">
          <template #body="row">
            <template v-if="row.data.link_dropbox !== null">
              <a
                :href="row.data.link_dropbox"
                target="_blank"
                v-if="row.data.typology === 'to_upload'"
              >
                <i class="pi pi-file" />
              </a>
              <a
                :href="row.data.file_to_approve_url"
                target="_blank"
                v-if="row.data.typology === 'to_approve'"
              >
                <i class="pi pi-file" />
              </a>
            </template>
          </template>
        </Column>

        <Column header="Assegnata a" class="task-assigned-to">
          <template #body="row">
            {{ row.data.user.full_name }}
          </template>
        </Column>

        <Column class="task-done">
          <template #body="row">
            <Chip
              class="chip-waiting"
              label="Documento non approvato"
              v-if="!row.data.done && row.data.typology === 'to_approve'"
            />

            <Chip
              class="chip-ok"
              label="Documento approvato"
              v-if="row.data.done && row.data.typology === 'to_approve'"
            />

            <Chip
              class="chip-waiting"
              label="Documento non caricato"
              v-if="!row.data.done && row.data.typology === 'to_upload'"
            />

            <Chip
              class="chip-ok"
              label="Documento caricato"
              v-if="row.data.done && row.data.typology === 'to_upload'"
            />
          </template>
        </Column>

        <Column class="task-actions">
          <template #body="row">
            <Button
              icon="pi pi-trash"
              class="
                delete-btn
                p-button
                p-component
                p-button-icon-only
                p-button-rounded
                p-button-danger
                p-button-text
              "
              @click="deleteTask($event, row.data)"
          /></template>
        </Column>
      </DataTable>
    </TabPanel>

    <TabPanel header="Attività MSB" v-if="!loading">
      <Toolbar class="pl-0 pr-0">
        <template #end>
          <Button
            label="Aggiungi attività"
            class="p-button-rounded p-button-sm mr-2"
            icon="pi pi-plus"
            @click="openMsbForm()"
          />

          <Button
            label="Import da Excel"
            class="p-button-rounded p-button-sm"
            icon="pi pi-cloud-upload"
          />
        </template>
      </Toolbar>

      <h4 class="col-12 text-center mt-5" v-if="msbTasks.length == 0">
        Nessuna attività
      </h4>
      <DataTable :value="msbTasks" v-else>
        <Column bodyClass="timeline msb-timeline">
          <template #body="row">
            <template v-if="row.data.typology == 'milestone'">
              <i
                class="pi pi-star-fill"
                v-if="row.data.done"
                @click="taskUndone(row.data)"
              />
              <i class="pi pi-star" v-else @click="taskDone(row.data)" />
            </template>
            <template v-else>
              <i
                class="pi pi-check-circle"
                v-if="row.data.done"
                @click="taskUndone(row.data)"
              />
              <i class="pi pi-circle" v-else @click="taskDone(row.data)" />
            </template>
          </template>
        </Column>
        <Column
          field="due_date"
          header="Data Consegna"
          class="task-due-date-column"
        >
          <template #body="row">
            {{
              row.data.due_date !== null
                ? this.$moment(row.data.dueDate).format("DD/MM/YYYY")
                : "-"
            }}
          </template>
        </Column>

        <Column
          field="title"
          header="Titolo"
          class="task-title-column"
        ></Column>

        <Column field="note" header="Descrizione" class="task-note-column">
          <template #body="row">
            <i
              class="pi pi-comment"
              v-if="row.data.note !== null && row.data.note !== ''"
              v-tooltip.top="`${row.data.note}`"
            />
          </template>
        </Column>

        <Column field="document" header="Documento" class="task-file-column">
          <template #body="row">
            <template v-if="row.data.link_dropbox !== null">
              <a
                :href="row.data.link_dropbox"
                target="_blank"
                v-if="row.data.typology === 'to_upload'"
              >
                <i class="pi pi-file" />
              </a>
              <a
                :href="row.data.file_to_approve_url"
                target="_blank"
                v-if="row.data.typology === 'to_approve'"
              >
                <i class="pi pi-file" />
              </a>
            </template>
          </template>
        </Column>

        <Column header="Assegnata a" class="task-assigned-to">
          <template #body="row">
            {{ row.data.user.full_name }}
          </template>
        </Column>

        <Column
          field="weight"
          header="Peso"
          class="task-weight-column"
          v-if="openMsbForm"
        ></Column>

        <Column class="task-actions">
          <template #body="row">
            <Button
              icon="pi pi-trash"
              class="
                delete-btn
                p-button
                p-component
                p-button-icon-only
                p-button-rounded
                p-button-danger
                p-button-text
              "
              @click="deleteTask($event, row.data)"
          /></template>
        </Column>
      </DataTable>
    </TabPanel>
  </TabView>

  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-d-flex p-p-4">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
        <p class="p-pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>

  <Dialog
    id="task-form"
    v-model:visible="isFormVisible"
    :modal="true"
    :closable="true"
    :closeOnEscape="false"
    :showHeader="true"
  >
    <template #header>
      <h3>
        {{
          tabActiveIndex == 0 ? "Nuova attività cliente" : "Nuova attività MSB"
        }}
      </h3>
    </template>

    <div class="p-fluid">
      <form class="mt-4" @submit.prevent="submit">
        <FormInputText
          type="text"
          name="title"
          label="Titolo *"
          :rules="form.title.rules"
          :value="form.title.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <div class="p-field mt-2">
          <label> Descrizione </label>

          <Textarea v-model="form.note.value" rows="5" cols="30" />

          <small class="mt-1 mb-5 p-error`"></small>
        </div>

        <FormInputText
          type="number"
          name="weight"
          class="mt-4"
          label="Peso attività *"
          :rules="form.weight.rules"
          :value="form.weight.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
          v-if="tabActiveIndex == 1"
        />

        <div class="p-field mt-4">
          <label> Data di scadenza </label>

          <Calendar
            v-model="form.dueDate"
            :inline="false"
            dateFormat="dd/mm/yy"
          />

          <small class="mt-1 mb-5 p-error`"></small>
        </div>

        <div class="p-field mt-4 mb-4">
          <div class="p-field-radiobutton mb-2">
            <RadioButton
              name="upload_approve"
              value="to_upload"
              v-model="form.uploadApprove"
            />
            <label class="ml-2">Carica documento</label>
          </div>
          <div class="p-field-radiobutton mb-2" v-if="tabActiveIndex == 0">
            <RadioButton
              name="upload_approve"
              value="to_approve"
              v-model="form.uploadApprove"
            />
            <label class="ml-2">Approva</label>
          </div>
          <div class="p-field-radiobutton mb-2" v-if="tabActiveIndex == 1">
            <RadioButton
              name="upload_approve"
              value="milestone"
              v-model="form.uploadApprove"
            />
            <label class="ml-2">Milestone</label>
          </div>
        </div>

        <div class="mt-1 mb-5">
          <label v-if="tabActiveIndex == 1"> Documento allegato * </label>
          <label v-else>
            {{
              form.uploadApprove == "to_approve"
                ? "Documento da approvare *"
                : "Documento da compilare *"
            }}</label
          >

          <AutoComplete
            v-model="form.dropboxFile"
            :suggestions="dropboxFiles"
            :dropdown="true"
            :forceSelection="true"
            @complete="searchDropboxFiles($event)"
            field="name"
          />
        </div>

        <div class="mt-1 mb-5" v-if="tabActiveIndex == 1">
          <label> Attività assegnata a *</label>

          <AutoComplete
            v-model="form.teamMember"
            :suggestions="teamMembers"
            :dropdown="true"
            :forceSelection="true"
            @complete="searchProjectManager($event)"
            field="full_name"
          />
        </div>
      </form>
    </div>

    <template #footer>
      <div class="mt-4 text-right">
        <Button type="submit" label="Salva" @click="submitTask" />
      </div>
    </template>
  </Dialog>
</template>


<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";

import {
  TASKS_CUSTOMERS,
  TASKS_MSB,
  TASKS_CREATE,
  TASKS_DELETE,
  TASKS_DONE,
  TASKS_UNDONE,
  TASKS_DOCUMENT_FILES,
} from "@/store/tasks.module";
import { ADMINS_ALL } from "@/store/admins.module";

export default {
  name: "TasksTab",

  props: {
    project: {
      required: true,
      typeof: Object,
    },
  },

  data() {
    return {
      tabActiveIndex: 0,

      teamMembers: [],

      isFormVisible: false,

      /// Form
      validateForm: false,

      dropboxFiles: [],

      form: {
        title: {
          error: "",
          rules: { required: true },
          value: "",
        },

        note: {
          error: "",
          rules: { required: false },
          value: "",
        },

        privateNote: {
          error: "",
          rules: { required: false },
          value: "",
        },

        weight: {
          error: "",
          rules: { required: true },
          value: "",
        },

        dueDate: null,

        teamMember: null,

        uploadApprove: "to_upload",

        dropboxFile: null,
      },
    };
  },

  computed: {
    allDropboxFiles() {
      return this.$store.state.tasks.allDocumentFiles;
    },

    allProjectManagers() {
      return this.$store.state.admins.all;
    },

    msbTasks() {
      return this.$store.state.tasks.msb;
    },

    customerTasks() {
      return this.$store.state.tasks.customers;
    },

    isCustomerFormValid() {
      return (
        this.form.title.error === "" &&
        this.form.uploadApprove !== null &&
        this.form.uploadApprove !== "" &&
        this.form.dropboxFile !== ""
      );
    },

    isMsbFormValid() {
      return (
        this.form.title.error === "" &&
        this.form.uploadApprove !== null &&
        this.form.uploadApprove !== "" &&
        this.form.weight.error === "" &&
        this.form.teamMember !== null
      );
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    cleanUpForm() {
      this.form.title.value = "";
      this.form.title.errors = "";

      this.form.note.value = "";
      this.form.note.errors = "";

      this.form.weight.value = "";
      this.form.weight.errors = "";

      this.form.uploadApprove = "to_upload";
      this.form.dropboxFile = null;
    },

    deleteTask(event, task) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Sicuro di voler eliminare l'attività?",
        icon: "pi pi-exclamation-triangle",

        accept: () => {
          this.$store
            .dispatch(TASKS_DELETE, task.id)
            .then(() => {
              this.successDeleteCallback();
            })
            .catch((err) => {
              this.$store.dispatch(HIDE_LOADER);
              this.errorCallback(err);
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    errorCallback(err) {
      this.$store.dispatch(HIDE_LOADER);

      this.$toast.add({
        detail: err[0],
        life: 3000,
        severity: "error",
        summary: "Errore",
      });
    },

    async fetchCustomerTasks() {
      this.$store.dispatch(SHOW_LOADER);

      await this.$store
        .dispatch(TASKS_CUSTOMERS, { projectId: this.project.id })
        .catch(() => this.$store.dispatch(HIDE_LOADER));

      await this.fetchDropboxFiles();

      this.$store.dispatch(HIDE_LOADER);
      this.loading = false;
    },

    async fetchMsbTasks() {
      this.$store.dispatch(SHOW_LOADER);

      await this.$store
        .dispatch(TASKS_MSB, { projectId: this.project.id })
        .catch(() => this.$store.dispatch(HIDE_LOADER));

      await this.fetchDropboxFiles();

      this.$store.dispatch(HIDE_LOADER);
      this.loading = false;
    },

    async fetchDropboxFiles() {
      this.$store.dispatch(SHOW_LOADER);

      await this.$store
        .dispatch(TASKS_DOCUMENT_FILES, { projectId: this.project.id })
        .catch(() => this.$store.dispatch(HIDE_LOADER));
    },

    fetchProjectManagers() {
      this.$store.dispatch(ADMINS_ALL);
    },

    openCustomerForm() {
      if (this.project.customer === null) {
        this.errorCallback([
          "Per creare un'attività è necessario associare l'utente al progetto",
        ]);
        return;
      }

      this.cleanUpForm();

      this.isFormVisible = true;
    },

    openMsbForm() {
      this.cleanUpForm();
      this.isFormVisible = true;
    },

    searchDropboxFiles(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.dropboxFiles = [...this.allDropboxFiles];
        } else {
          this.dropboxFiles = this.allDropboxFiles.filter((file) => {
            return file.name.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    searchProjectManager(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.teamMembers = [...this.allProjectManagers];
        } else {
          this.teamMembers = this.allProjectManagers.filter((pm) => {
            return pm.full_name
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    submitTask() {
      this.validateForm = true;

      if (this.tabActiveIndex == 0 && !this.isCustomerFormValid) {
        return;
      }

      if (this.tabActiveIndex == 1 && !this.isMsbFormValid) {
        return;
      }

      this.$store.dispatch(SHOW_LOADER);

      const params = {
        task: {
          note: this.form.note.value,
          title: this.form.title.value,
          link_dropbox:
            this.form.dropboxFile !== null
              ? this.form.dropboxFile.shared_link
              : null,
          path_dropbox:
            this.form.dropboxFile !== null ? this.form.dropboxFile.path : null,
          project_id: this.project.id,
          user_id:
            this.tabActiveIndex == 1
              ? this.form.teamMember.id
              : this.project.customer.id,
          typology: this.form.uploadApprove,
          weight: this.form.weight.value,
          msb: this.tabActiveIndex == 1,
          due_date:
            this.form.dueDate !== null
              ? this.$moment(this.form.dueDate).format("YYYY-MM-DD")
              : null,
        },
      };

      this.$store
        .dispatch(TASKS_CREATE, params)
        .then(() => {
          this.successCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);

          this.errorCallback(err);
        });
    },

    async successDeleteCallback() {
      this.openCustomerForm = false;
      this.openMsbForm = false;

      if (this.tabActiveIndex == 0) {
        await this.fetchCustomerTasks();
      } else {
        await this.fetchMsbTasks();
      }

      this.$toast.add({
        detail: "Attività eliminata correttamente",
        life: 3000,
        severity: "success",
        summary: "Attività progetto",
      });
    },

    doneUndoneCallback() {
      if (this.tabActiveIndex == 0) {
        this.fetchCustomerTasks();
      } else {
        this.fetchMsbTasks();
      }
    },

    async successCallback() {
      if (this.tabActiveIndex == 0) {
        await this.fetchCustomerTasks();
      } else {
        await this.fetchMsbTasks();
      }

      this.openCustomerForm = false;
      this.openMsbForm = false;

      this.$toast.add({
        detail: "Attività salvata correttamente",
        life: 3000,
        severity: "success",
        summary: "Attività progetto",
      });
    },

    tabChange(e) {
      this.tabActiveIndex = e.index;
    },

    tabClick(e) {
      this.tabActiveIndex = e.index;

      switch (this.tabActiveIndex) {
        case 0: // Customer
          this.fetchCustomerTasks();
          break;

        case 1: // MSB
          this.fetchMsbTasks();
          this.fetchProjectManagers();
          break;

        default:
          break;
      }
    },

    taskUndone(task) {
      this.$store.dispatch(SHOW_LOADER);

      this.$store.dispatch(TASKS_UNDONE, task.id).finally(() => {
        this.doneUndoneCallback();
      });
    },

    taskDone(task) {
      this.$store.dispatch(SHOW_LOADER);
      this.$store
        .dispatch(TASKS_DONE, task.id)

        .finally(() => {
          this.doneUndoneCallback();
        });
    },

    updateFormInputText({ error, name, value }) {
      this.form[`${name}`].error = error;
      this.form[`${name}`].value = value;
    },
  },

  mounted() {
    this.fetchCustomerTasks();
  },
};
</script>


<style lang="scss" >
@import "@/assets/theme/_variables.scss";

.timeline {
  overflow-y: hidden;
  padding-bottom: 3rem !important;
  padding-top: 0 !important;
  position: relative;
  width: 80px;

  .pi {
    background-color: #fff;
    color: $primaryColor;
    font-size: 20px;
    font-weight: bold;
    margin-top: 1.5rem;
    padding: 6px;
    top: 10px;
    position: relative;
  }

  &.msb-timeline {
    .pi {
      color: #e18f39;
    }
  }

  &::before {
    background-color: $shade300;

    content: " ";
    height: 100%;
    margin-left: 14px;
    margin-top: 0;
    position: absolute;
    width: 4px;
  }
}
// .p-datatable-thead {
//   display: none;
// }

#task-tab {
  .p-tabview-panels {
    background-color: #fff !important;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  .p-highlight .p-tabview-nav-link {
    background-color: #fff !important;
    border: 1px solid #ddd;
    border-bottom: 1px solid #fff;
  }

  tr > td {
    border-bottom: none;
  }

  .task-due-date-column {
    text-align: center;
    width: 120px;
  }
  .task-note-column,
  .task-file-column {
    text-align: center;
    width: 40px;

    .pi {
      color: $primaryColor;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .task-title-column {
    min-width: 30%;
    width: 30%;
  }
}

#task-form {
  .p-dialog-content {
    min-width: 500px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .p-dialog-header {
    padding-bottom: 0;

    h3 {
      color: #333;
      font-weight: 700;
    }
  }

  label {
    color: $shade600;
    font-weight: 700;
    font-size: 0.8rem;
  }
}
.chip-waiting {
  background-color: $shade600 !important;
}
</style>