import AuthService from '../services/auth.service';

// action types
export const AUTH_LOGIN = "auth/login";
export const AUTH_LOGOUT = "auth/logout";

// Carico user dal localStorage
const user = JSON.parse(localStorage.getItem('user'));

export const auth = {
  namespaced: true,

  state: user
    ? { loggedIn: true, user }
    : { loggedIn: false, user: null },

  actions: {
    async login(ctx, params) {
      await AuthService.login(params).then(
        result => {
          debugger;
          if (result.user.role === 'admin') {
            ctx.commit('loginSuccess', user);
            return Promise.resolve(user);
          }
          else {
            ctx.commit('loginFailure');
            return Promise.reject(["L'accesso all'area è consentito solo agli amministratori."]);
          }
        },
        error => {
          ctx.commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },

    logout(ctx) {
      AuthService.logout();
      ctx.commit('logout');
    },

    // register(ctx, user) {
    //   return AuthService.register(user).then(
    //     response => {
    //       ctx.commit('registerSuccess');
    //       return Promise.resolve(response.data);
    //     },
    //     error => {
    //       ctx.commit('registerFailure');
    //       return Promise.reject(error);
    //     }
    //   );
    // }
  },

  getters: {
    currentUser: state => state.user,

    isLoggedIn: state => state.loggedIn,

  },

  mutations: {
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.user = user;
    },

    loginFailure(state) {
      state.loggedIn = false;
      state.user = null;
    },

    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },
  }
};