import ApiService from './api.service';

class PaymentsService {
  async allPayments(projectId) {
    return await ApiService.get(`/projects/${projectId}/invoices`);
  }

  async createPayment(params) {
    return await ApiService.post('/invoices', params);
  }

  async fetchDropboxFiles(projectId) {
    return await ApiService.get(`/projects/${projectId}/invoices/dropbox_files`);
  }

  async deletePayment(id) {
    return await ApiService.delete(`/invoices/${id}`);
  }

  async toPayInvoice(id) {
    return await ApiService.post(`/invoices/${id}/to_pay`);
  }

  async payInvoice(id) {
    return await ApiService.post(`/invoices/${id}/pay`);
  }

}

export default new PaymentsService();
