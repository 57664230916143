<template>
  <div
    id="sidebar"
    class="
      h-screen
      hidden
      lg:block
      flex-shrink-0
      absolute
      lg:static
      left-0
      top-0
      z-1
      select-none
      animation-duration-300 animation-ease-in-out
    "
    style="width: 280px; backdrop-filter: blur(10px)"
  >
    <div class="flex flex-column h-full">
      <div class="flex align-items-center px-5">
        <div class="w-full">
          <Logo size="small" color="light" />
        </div>
      </div>
      <div class="overflow-y-auto">
        <ul class="list-none p-3 m-0">
          <li>
            <ul id="nav" class="list-none p-0 m-0 overflow-hidden">
              <li>
                <router-link to="/">
                  <i class="pi pi-home mr-2"></i>
                  <span class="font-medium">Dashboard</span>
                  <span class="p-ink"></span>
                </router-link>
              </li>
              <li>
                <router-link to="/projects">
                  <i class="pi pi-th-large mr-2"></i>
                  <span class="font-medium">Progetti</span>
                  <span class="p-ink"></span>
                </router-link>
              </li>
              <li>
                <router-link to="/users">
                  <i class="pi pi-user mr-2"></i>
                  <span class="font-medium">Utenti</span>
                  <span class="p-ink"></span>
                </router-link>
              </li>
              <li>
                <router-link to="/team">
                  <i class="pi pi-users mr-2"></i>
                  <span class="font-medium">Team</span>
                  <span class="p-ink"></span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/logo/Logo.vue";
export default {
  components: {
    Logo,
  },

  data() {
    return {
      items: [
        {
          label: "Update",
          icon: "pi pi-refresh",
          command: () => {
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: "Data Updated",
              life: 3000,
            });
          },
        },
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => {
            this.$toast.add({
              severity: "warn",
              summary: "Delete",
              detail: "Data Deleted",
              life: 3000,
            });
          },
        },
        {
          label: "Vue Website",
          icon: "pi pi-external-link",
          url: "https://vuejs.org/",
        },
        {
          label: "Router",
          icon: "pi pi-upload",
          to: "/fileupload",
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/theme/_variables.scss"

#sidebar
  background-color: #1e1f2d
  height: 100vh
  padding: 40px 0
  widows: 250px

  #nav
    list-style: none

    li
      padding: 5px 0
      text-align: left

      a
        color: #fff
        font-family: $fontFamily
        line-height: 30px
        text-decoration: none
        vertical-align: middle

        &:hover
          color: $primaryColor

        &.router-link-active
          color: $primaryColor

  /// LOGO
  #logo
    background-color: #fff
    height: 150px
    margin: auto
    width: 150px
</style>