<template>
  <div :class="`msb-logo ${$props.size} ${$props.color}`">
    <h1 class="mt-4">
      MARTINI<br />
      SACCO<br />
      BERTOLOTTO
    </h1>
    <h2 class="font-weight-normal">ARCHITECTURE ENGINEERING</h2>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      default: "dark",
      typeof: String,
    },
    size: {
      default: "regular",
      typeof: String,
    },
  },
};
</script>

<style scoped>
.light > * {
  color: #fff;
}

.msb-logo.small h1 {
  font-size: 1.5rem;
}

.msb-logo.small h2 {
  font-size: 1.05rem;
}
</style>