import PaymentsService from '../services/payments.service';

// action types
export const PAYMENTS_ALL = "payments/fetchAll";
export const PAYMENTS_CREATE = "payments/createPayment";
export const PAYMENTS_DELETE = "payments/deletePayment";
export const PAYMENTS_PAYED_INVOICE = "payments/payInvoice";
export const PAYMENTS_TO_PAY_INVOICE = "payments/toPayInvoice";

export const PAYMENTS_FILES = "payments/fetchDropboxFiles";


export const payments = {
  namespaced: true,

  state: {
    all: [],
    dropboxFiles: []
  },

  actions: {
    fetchAll(ctx, { projectId }) {
      PaymentsService.allPayments(projectId).then(
        response => {
          ctx.commit('setPayments', response.data.invoices);
          return Promise.resolve(response.data.invoices);
        },
        error => {
          ctx.commit('setPayments', []);
          return Promise.reject(error);
        });
    },

    createPayment(ctx, params) {
      return PaymentsService.createPayment(params);
    },

    fetchDropboxFiles(ctx, { projectId }) {
      PaymentsService.fetchDropboxFiles(projectId).then(
        response => {
          ctx.commit('setDropboxFiles', response.data.files);
          return Promise.resolve(response.data.files);
        },
        error => {
          ctx.commit('setDropboxFiles', []);
          return Promise.reject(error);
        });
    },

    deletePayment(ctx, id) {
      return PaymentsService.deletePayment(id);
    },

    payInvoice(ctx, id) {
      return PaymentsService.payInvoice(id);
    },

    toPayInvoice(ctx, id) {
      return PaymentsService.toPayInvoice(id);
    },
  },

  getters: {
  },

  mutations: {
    setPayments(state, payments) {
      state.all = payments;
    },

    setDropboxFiles(state, files) {
      state.dropboxFiles = files;
    }
  }
};