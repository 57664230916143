<template>
  <div id="dashboard-view" class="col-12"></div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

// import SidebarMenu from "@/components/SidebarMenu.vue";
// import TopWidget from "@/components/dashboard/TopWidget.vue";

export default {
  components: {
    // TopWidget,
  },

  data() {
    return {
      notifications: [
        {
          date: Date("2021-12-20"),
          title: "Federica Toso ha caricato un documento",
          description: "Lorem ipsum",
          projectName: "Villa Tirone",
        },
        {
          date: Date("2021-12-25"),
          title: "Federica Toso ha caricato un documento",
          description: "Lorem ipsum",
          projectName: "Villa Natale",
        },
      ],
    };
  },
  name: "Dashboard",
};
</script>
