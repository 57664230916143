<template>
  <Tree
    :value="files"
    @nodeExpand="documentsExpandFolder"
    @nodeCollapse="documentsCollapseFolder"
    @nodeSelect="openDocument"
    selectionMode="single"
    :loading="documentsTabLoading"
  />
</template>


<script>
import { PROJECT_DOCUMENTS } from "@/store/projects.module";
export default {
  props: {
    project: {
      typeof: Object,
      required: true,
    },
  },

  data() {
    return {
      documentsTabLoading: false,
      files: [],
    };
  },

  methods: {
    documentsExpandFolder(folder) {
      folder.icon = "pi pi-folder-open";
      this.fetchDocuments(folder);
    },

    documentsCollapseFolder(folder) {
      folder.icon = "pi pi-folder";
    },

    fetchDocuments(folder) {
      this.documentsTabLoading = true;

      if (folder !== undefined) {
        if (folder.children) {
          // Ho già caricato la cartella
          this.documentsTabLoading = false;
          return;
        }

        folder.children = [];
      }

      this.$store
        .dispatch(PROJECT_DOCUMENTS, {
          projectId: this.project.id,
          path: folder === undefined ? this.project.dropbox_path : folder.path,
        })
        .then((results) => {
          console.log(results);

          const files = results.map((file) => {
            return {
              key: file.id,
              label: file.name,
              path: file.path,
              leaf: !file.is_folder,
              selectable: !file.is_folder,
              icon: file.is_folder ? "pi pi-folder" : "pi pi-file",
              shared_link: file.shared_link,
            };
          });

          if (folder === undefined) {
            this.files = files;
          } else {
            folder.children = files;
          }
        })
        .finally(() => (this.documentsTabLoading = false));
    },

    openDocument(file) {
      window.open(file.shared_link, "_blank");
    },
  },

  mounted() {
    this.fetchDocuments();
  },
};
</script>

<style lang="scss">
@import "@/assets/theme/_variables.scss";

.p-treenode-icon {
  color: $primaryColor !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  box-shadow: none !important;
}
</style>