import axios from 'axios';
import ApiService from './api.service';

const USER_KEY = 'user';

class AuthService {
  async login(auth) {
    const response = await axios
      .post('/auth/login', {
        email: auth.email,
        password: auth.password
      });
    ApiService
    // Salvo gli header della sessione
    ApiService.storeHeaders(JSON.stringify(response.headers));
    this.storeCurrentUser(JSON.stringify(response.data.user));

    return response.data;
  }

  storeCurrentUser(user) {
    localStorage.setItem(USER_KEY, user);
  }


  logout() {
    localStorage.removeItem(USER_KEY);
    ApiService.storeHeaders(null);
  }
}

export default new AuthService();
