import TasksService from '../services/tasks.service';

// action types
export const TASKS_CUSTOMERS = "tasks/fetchCustomers";
export const TASKS_MSB = "tasks/fetchMsb";
export const TASKS_CREATE = "tasks/createTask";
export const TASKS_DELETE = "tasks/deleteTask";
export const TASKS_DONE = "tasks/doneTask";
export const TASKS_UNDONE = "tasks/undoneTask";
export const TASKS_DOCUMENT_FILES = "tasks/fetchDocumentFiles";


export const tasks = {
  namespaced: true,

  state: {
    customers: [],
    msb: [],
    allDocumentFiles: []
  },

  actions: {
    fetchCustomers(ctx, { projectId }) {
      TasksService.customerTasks(projectId).then(
        response => {
          ctx.commit('setCustomerTasks', response.data.tasks);
          return Promise.resolve(response.data.tasks);
        },
        error => {
          ctx.commit('setCustomerTasks', []);
          return Promise.reject(error);
        });
    },

    fetchMsb(ctx, { projectId }) {
      TasksService.msbTasks(projectId).then(
        response => {
          ctx.commit('setMsbTasks', response.data.tasks);
          return Promise.resolve(response.data.tasks);
        },
        error => {
          ctx.commit('setMsbTasks', []);
          return Promise.reject(error);
        });
    },

    createTask(ctx, params) {
      return TasksService.createTask(params);
    },

    fetchDocumentFiles(ctx, { projectId }) {
      TasksService.dropboxDocumentFiles(projectId).then(
        response => {
          ctx.commit('setAllDocumentFiles', response.data.files);
          return Promise.resolve(response.data.files);
        },
        error => {
          ctx.commit('setAllDocumentFiles', []);
          return Promise.reject(error);
        });
    },


    deleteTask(ctx, id) {
      return TasksService.deleteTask(id);
    },

    undoneTask(ctx, id) {
      return TasksService.undoneTask(id);
    },
    doneTask(ctx, id) {
      return TasksService.doneTask(id);
    }

  },

  getters: {
  },

  mutations: {
    setMsbTasks(state, tasks) {
      state.msb = tasks;
    },

    setCustomerTasks(state, tasks) {
      state.customers = tasks;
    },

    setAllDocumentFiles(state, files) {
      state.allDocumentFiles = files;
    }
  }
};