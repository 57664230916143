<template>
  <div id="users-view">
    <div class="header">
      <div class="container align-content-start">
        <div class="col-12">
          <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" />
        </div>
      </div>
      <!-- <div class="col-2 col-offset-10 text-right">
        <div class="pt-2 pb-2">
          <Button
            label="Aggiungi Progetto"
            class="p-button-rounded"
            icon="pi pi-plus"
            @click="openForm"
          />
        </div> 
      </div>-->
    </div>

    <div class="content col-12">
      <div class="container grid align-content-start">
        <TabView class="col-12" :activeIndex="activeIndex">
          <TabPanel header="Progetti attivi">
            <Toolbar>
              <template #start></template>

              <template #end>
                <Button
                  label="Aggiungi Progetto"
                  class="p-button-rounded p-button-sm"
                  icon="pi pi-plus"
                  @click="openForm"
                />
              </template>
            </Toolbar>

            <div class="grid align-content-start">
              <h4 class="col-12 text-center mt-5" v-if="projects.length == 0">
                Nessun progetto
              </h4>

              <div class="col-4" v-for="project in projects" :key="project.id">
                <ProjectCard
                  :project="project"
                  @openEditForm="openForm(project)"
                  @deleteItem="deleteProject(project)"
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Progetti archiviati">
            <div class="grid align-content-start">
              <h4
                class="col-12 text-center mt-5"
                v-if="archivedProjects.length == 0"
              >
                Nessun progetto
              </h4>

              <div
                class="col-4"
                v-for="project in archivedProjects"
                :key="project.id"
              >
                <ProjectCard
                  :project="project"
                  @openEditForm="openForm(project)"
                  @deleteItem="deleteProject(project)"
                />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>

  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-d-flex p-p-4">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
        <p class="p-pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>

  <Dialog
    id="user-form"
    v-model:visible="isFormVisible"
    :modal="true"
    :closable="true"
    :closeOnEscape="false"
    :showHeader="true"
  >
    <template #header>
      <h3>{{ project.id != null ? "Modifica Progetto" : "Nuovo Progetto" }}</h3>
    </template>

    <div class="p-fluid">
      <form class="mt-4" @submit.prevent="submit">
        <FormInputText
          type="text"
          name="name"
          label="Nome progetto*"
          :rules="form.name.rules"
          :value="form.name.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <FormInputText
          type="text"
          name="code"
          label="Codice commessa*"
          :rules="form.code.rules"
          :value="form.code.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <div class="p-field">
          <label> Inizio progetto </label>

          <Calendar
            v-model="form.startDate"
            :inline="false"
            dateFormat="dd/mm/yy"
          />

          <small class="mt-1 mb-5 p-error`"></small>
        </div>

        <FormInputText
          type="number"
          name="price"
          label="Importo"
          :rules="form.price.rules"
          :value="form.price.value"
          :validationEnabled="validateForm"
          :disabled="form.price.disabled"
          @update="updateFormInputText"
        />

        <div class="mt-1 mb-5">
          <label> Responsabile progetto *</label>

          <AutoComplete
            v-model="form.teamMember"
            :suggestions="teamMembers"
            :dropdown="true"
            :forceSelection="true"
            @complete="searchProjectManager($event)"
            field="full_name"
          />
        </div>

        <div class="mt-1 mb-5">
          <label> Referente progetto *</label>
          <AutoComplete
            v-model="form.customer"
            :suggestions="customers"
            :dropdown="true"
            :forceSelection="true"
            @complete="searchCustomer($event)"
            field="full_name"
          />
        </div>
      </form>
    </div>

    <template #footer>
      <div class="mt-4 text-right">
        <Button type="submit" label="Salva" @click="submit" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";
import {
  PROJECTS_ALL,
  PROJECTS_CREATE,
  PROJECTS_UPDATE,
  PROJECTS_DELETE,
  PROJECTS_ARCHIVED,
} from "@/store/projects.module";
import ProjectCard from "@/components/projects/ProjectCard";
import { ADMINS_ALL } from "../../store/admins.module";
import { USERS_ALL } from "../../store/users.module";

export default {
  data() {
    return {
      breadcrumb: {
        home: { icon: "pi pi-home", to: "/" },
        items: [{ label: "Progetti" }],
      },
      isFormVisible: false,
      project: null,

      /// Form
      validateForm: false,
      teamMembers: [],
      customers: [],

      form: {
        name: {
          error: "",
          rules: { required: true },
          value: "",
        },

        code: {
          error: "",
          rules: { required: true },
          value: "",
        },

        startDate: null,

        price: {
          error: "",
          rules: {},
          value: "",
        },

        teamMember: null,
        customer: null,
      },
    };
  },

  components: {
    ProjectCard,
  },

  computed: {
    allProjectManagers() {
      return this.$store.state.admins.all;
    },

    allCustomers() {
      return this.$store.state.users.all;
    },

    projects() {
      return this.$store.state.projects.all;
    },

    archivedProjects() {
      return this.$store.state.projects.archived;
    },

    isFormValid() {
      return (
        this.form.name.error === "" &&
        this.form.code.error === "" &&
        this.form.price.error === "" &&
        this.form.teamMember !== null
        // &&
        // this.form.customer !== null
      );
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    searchProjectManager(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.teamMembers = [...this.allProjectManagers];
        } else {
          this.teamMembers = this.allProjectManagers.filter((pm) => {
            return pm.full_name
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    searchCustomer(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.customers = [...this.allCustomers];
        } else {
          this.customers = this.allCustomers.filter((user) => {
            return user.full_name
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    cleanUpForm() {
      this.form.name.value = "";
      this.form.name.errors = "";

      this.form.code.errors = "";
      this.form.code.value = "";

      this.form.price.value = "";
      this.form.price.errors = "";

      this.startDate = null;
      this.teamMember = null;
    },

    deleteUser(selectedProject) {
      this.$store
        .dispatch(PROJECTS_DELETE, selectedProject.id)
        .then(() => {
          this.successDeleteCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.errorCallback(err);
        });
    },

    openForm(selectedProject) {
      this.cleanUpForm();

      this.project = selectedProject;

      this.form.name.value = selectedProject.name;
      this.form.code.value = selectedProject.code;
      this.form.startDate = selectedProject.startDate;
      this.form.teamMember = selectedProject.teamMember;

      this.isFormVisible = true;
    },

    updateFormInputText({ error, name, value }) {
      this.form[`${name}`].error = error;
      this.form[`${name}`].value = value;
    },

    submit() {
      this.validateForm = true;

      if (!this.isFormValid) {
        return;
      }

      this.$store.dispatch(SHOW_LOADER);
      this.isFormVisible = false;

      const params = {
        project: {
          name: this.form.name.value,
          code: this.form.code.value,
          start_date:
            this.form.startDate !== null
              ? this.$moment(this.form.startDate).format("YYYY-MM-DD")
              : null,
          team_id:
            this.form.teamMember !== null ? this.form.teamMember.id : null,
          user_id: this.form.customer !== null ? this.form.customer.id : null,
        },
      };

      if (this.project.id != null) {
        /// Update
        // TODO: formData

        this.$store
          .dispatch(PROJECTS_UPDATE, { params: params, id: this.project.id })
          .then(() => {
            this.successCallback();
          })
          .catch((err) => {
            this.$store.dispatch(HIDE_LOADER);
            this.isFormVisible = true;
            this.errorCallback(err);
          });
      } else {
        /// Create
        this.$store
          .dispatch(PROJECTS_CREATE, params)
          .then(() => {
            this.successCallback();
          })
          .catch((err) => {
            this.$store.dispatch(HIDE_LOADER);
            this.isFormVisible = true;
            this.errorCallback(err);
          });
      }
    },

    fetchAll() {
      this.$store.dispatch(SHOW_LOADER);

      this.$store
        .dispatch(PROJECTS_ALL)
        .then(() => {
          this.$store.dispatch(PROJECTS_ARCHIVED);
        })
        .then(() => {
          this.$store.dispatch(HIDE_LOADER);
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.errorCallback(err);
        });
    },

    fetchProjectManagers() {
      this.$store.dispatch(ADMINS_ALL);
    },

    fetchUsers() {
      this.$store.dispatch(USERS_ALL);
    },

    successDeleteCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchAll();

      this.$toast.add({
        detail: "Progetto eliminato correttamente",
        life: 3000,
        severity: "success",
        summary: "Team",
      });
    },

    successCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchAll();

      this.$toast.add({
        detail: "Progetto salvato correttamente",
        life: 3000,
        severity: "success",
        summary: "Team",
      });
    },

    errorCallback(err) {
      this.$toast.add({
        detail: err[0],
        life: 3000,
        severity: "error",
        summary: "Errore",
      });
    },
  },

  mounted() {
    this.fetchAll();
    this.fetchProjectManagers();
    this.fetchUsers();
  },

  name: "ProjectsView",
};
</script>

