<template>
  <Toolbar class="pl-0 pr-0">
    <template #end>
      <Button
        label="Aggiungi pagamento"
        class="p-button-rounded p-button-sm"
        icon="pi pi-plus"
        @click="openCustomerForm()"
      />
    </template>
  </Toolbar>
  <div class="flex align-items-stretch">
    <div class="col-8">
      <Card>
        <template #header> </template>
        <template #title> <h3>Fatture</h3></template>
        <template #content>
          <h4 class="col-12 text-center mt-5" v-if="payments.length == 0">
            Nessun pagamento
          </h4>

          <DataTable :value="payments" v-else id="payments-table">
            <Column bodyClass="timeline">
              <template #body="row">
                <i
                  class="pi pi-check-circle"
                  v-if="row.data.status === 'payed'"
                  @click="invoiceToPay(row.data)"
                />
                <i
                  class="pi pi-circle expired"
                  v-else
                  @click="invoicePayed(row.data)"
                />
              </template>
            </Column>

            <Column
              field="number"
              header="Numero fattura"
              class="payment-number-column"
            ></Column>

            <Column
              field="due_date"
              header="Data scadenza"
              class="payment-due-date-column"
            >
              <template #body="row">
                {{
                  row.data.due_date !== null
                    ? this.$moment(row.data.due_date).format("DD/MM/YYYY")
                    : "-"
                }}
              </template>
            </Column>

            <Column
              field="amount"
              header="Importo"
              class="payment-amount-column"
            >
              <template #body="row">
                {{ `€ ${Intl.NumberFormat().format(row.data.amount)}` }}
              </template>
            </Column>

            <Column
              field="document"
              header="Documento"
              class="payment-file-column"
            >
              <template #body="row">
                <template v-if="row.data.link_dropbox !== null">
                  <a :href="row.data.link_dropbox" target="_blank">
                    <i class="pi pi-file" />
                  </a>
                </template>
              </template>
            </Column>

            <!-- <
      <Column class="payment-done">
        <template #body="row">
          <Chip
            class="chip-waiting"
            label="Documento non approvato"
            v-if="!row.data.done && row.data.typology === 'to_approve'"
          />

          <Chip
            class="chip-ok"
            label="Documento approvato"
            v-if="row.data.done && row.data.typology === 'to_approve'"
          />

          <Chip
            class="chip-waiting"
            label="Documento non caricato"
            v-if="!row.data.done && row.data.typology === 'to_upload'"
          />

          <Chip
            class="chip-ok"
            label="Documento caricato"
            v-if="row.data.done && row.data.typology === 'to_upload'"
          />
        </template>
      </Column> -->

            <Column class="payment-actions">
              <template #body="row">
                <Button
                  icon="pi pi-trash"
                  class="
                    delete-btn
                    p-button
                    p-component
                    p-button-icon-only
                    p-button-rounded
                    p-button-danger
                    p-button-text
                  "
                  @click="deletePayment($event, row.data)"
              /></template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
    <div class="col-4">
      <Card class="chart-card">
        <template #header> </template>
        <template #title> <h3>Stato pagamenti</h3></template>
        <template #content>
          <Progress :progress="paymentProgress" :showTitle="true" />
        </template>
      </Card>
    </div>
  </div>

  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-d-flex p-p-4">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
        <p class="p-pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>

  <Dialog
    id="payment-form"
    v-model:visible="isFormVisible"
    :modal="true"
    :closable="true"
    :closeOnEscape="false"
    :showHeader="true"
  >
    <template #header>
      <h3>Nuova fattura</h3>
    </template>

    <div class="p-fluid">
      <form class="mt-4" @submit.prevent="submit">
        <FormInputText
          type="text"
          name="number"
          label="Numero fattura *"
          :rules="form.number.rules"
          :value="form.number.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <FormInputText
          type="text"
          name="amount"
          label="Importo *"
          :rules="form.amount.rules"
          :value="form.amount.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <div class="p-field mt-4">
          <label> Data di scadenza *</label>

          <Calendar
            v-model="form.dueDate"
            :inline="false"
            dateFormat="dd/mm/yy"
          />

          <small class="mt-1 mb-5 p-error`"></small>
        </div>

        <div class="mt-4 mb-5">
          <label> Allegato fattura * </label>

          <AutoComplete
            v-model="form.dropboxFile"
            :suggestions="dropboxFiles"
            :dropdown="true"
            :forceSelection="true"
            @complete="searchDropboxFiles($event)"
            field="name"
          />
        </div>
      </form>
    </div>

    <template #footer>
      <div class="mt-4 text-right">
        <Button type="submit" label="Salva" @click="submit" />
      </div>
    </template>
  </Dialog>
</template>


<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";
import {
  PAYMENTS_ALL,
  PAYMENTS_FILES,
  PAYMENTS_CREATE,
  PAYMENTS_DELETE,
  PAYMENTS_PAYED_INVOICE,
  PAYMENTS_TO_PAY_INVOICE,
} from "@/store/payments.module";
import Progress from "@/components/projects/Progress.vue";

export default {
  name: "PaymentsTab",

  components: {
    Progress,
  },

  emits: ["fetchProject"],

  props: {
    project: {
      required: true,
      typeof: Object,
    },
  },

  data() {
    return {
      isFormVisible: false,

      /// Form
      validateForm: false,

      dropboxFiles: [],

      form: {
        number: {
          error: "",
          rules: { required: true },
          value: "",
        },

        amount: {
          error: "",
          rules: { required: true },
          value: "",
        },
        dueDate: null,

        dropboxFile: null,
      },
    };
  },

  computed: {
    allDropboxFiles() {
      return this.$store.state.payments.dropboxFiles;
    },

    payments() {
      return this.$store.state.payments.all;
    },

    isFormValid() {
      return (
        this.form.number.error === "" &&
        this.form.amount.error === "" &&
        this.form.uploadApprove !== null &&
        this.form.uploadApprove !== "" &&
        this.form.dropboxFile !== ""
      );
    },

    paymentProgress() {
      return {
        series: [
          {
            percentage: this.project.payments_data.to_pay_percentage,
            color: "#F3E213",
            label: `${this.project.payments_data.to_pay_percentage}%`,
            title: "Fatture in attesa di pagamento",
          },
          {
            percentage: this.project.payments_data.payed_percentage,
            color: "#208423",
            label: `${this.project.payments_data.payed_percentage}%`,
            title: "Fatture pagate",
          },
        ],
      };
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    cleanUpForm() {
      this.form.number.value = "";
      this.form.number.errors = "";

      this.form.amount.value = "";
      this.form.amount.errors = "";

      this.dueDate = null;
      this.form.dropboxFile = null;
    },

    deletePayment(event, payment) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Sicuro di voler eliminare il pagamento?",
        icon: "pi pi-exclamation-triangle",

        accept: () => {
          this.$store
            .dispatch(PAYMENTS_DELETE, payment.id)
            .then(() => {
              this.successDeleteCallback();
            })
            .catch((err) => {
              this.$store.dispatch(HIDE_LOADER);
              this.errorCallback(err);
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    errorCallback(err) {
      this.$store.dispatch(HIDE_LOADER);

      this.$toast.add({
        detail: err[0],
        life: 3000,
        severity: "error",
        summary: "Errore",
      });
    },

    async fetchPayments() {
      this.$store.dispatch(SHOW_LOADER);

      await this.$store
        .dispatch(PAYMENTS_ALL, { projectId: this.project.id })
        .catch(() => this.$store.dispatch(HIDE_LOADER));

      await this.fetchDropboxFiles();
    },

    async fetchDropboxFiles() {
      this.$store.dispatch(SHOW_LOADER);

      await this.$store
        .dispatch(PAYMENTS_FILES, { projectId: this.project.id })
        .catch(() => this.$store.dispatch(HIDE_LOADER));

      this.$store.dispatch(HIDE_LOADER);
    },

    openCustomerForm() {
      if (this.project.customer === null) {
        this.errorCallback([
          "Per creare un'pagamento è necessario associare l'utente al progetto",
        ]);
        return;
      }

      this.cleanUpForm();

      this.isFormVisible = true;
    },

    openMsbForm() {
      this.cleanUpForm();
      this.isFormVisible = true;
    },

    searchDropboxFiles(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.dropboxFiles = [...this.allDropboxFiles];
        } else {
          this.dropboxFiles = this.allDropboxFiles.filter((file) => {
            return file.name.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    submit() {
      this.validateForm = true;

      if (!this.isFormValid) {
        return;
      }

      this.$store.dispatch(SHOW_LOADER);

      const params = {
        invoice: {
          number: this.form.number.value,
          amount: this.form.amount.value,
          link_dropbox:
            this.form.dropboxFile !== null
              ? this.form.dropboxFile.shared_link
              : null,
          project_id: this.project.id,
          due_date:
            this.form.dueDate !== null
              ? this.$moment(this.form.dueDate).format("YYYY-MM-DD")
              : null,
        },
      };

      this.$store
        .dispatch(PAYMENTS_CREATE, params)
        .then(() => {
          this.successCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);

          this.errorCallback(err);
        });
    },

    successDeleteCallback() {
      this.fetchPayments();
      this.$store.dispatch(HIDE_LOADER);
      this.$emit("fetchProject");

      this.$toast.add({
        detail: "Fattura eliminata correttamente",
        life: 3000,
        severity: "success",
        summary: "Pagamenti",
      });
    },

    async successCallback() {
      this.isFormVisible = false;

      this.fetchPayments();
      this.$emit("fetchProject");

      this.$toast.add({
        detail: "Fattura salvata correttamente",
        life: 3000,
        severity: "success",
        summary: "Pagamenti",
      });
    },

    updateFormInputText({ error, name, value }) {
      this.form[`${name}`].error = error;
      this.form[`${name}`].value = value;
    },

    invoiceToPay(invoice) {
      this.$store.dispatch(SHOW_LOADER);

      this.$store.dispatch(PAYMENTS_TO_PAY_INVOICE, invoice.id).finally(() => {
        this.toPayPayedCallback();
      });
    },

    invoicePayed(invoice) {
      this.$store.dispatch(SHOW_LOADER);
      this.$store.dispatch(PAYMENTS_PAYED_INVOICE, invoice.id).finally(() => {
        this.toPayPayedCallback();
      });
    },

    toPayPayedCallback() {
      this.$store.dispatch(HIDE_LOADER);
      this.$emit("fetchProject");
      this.fetchPayments();
    },
  },

  mounted() {
    this.fetchPayments();
  },
};
</script>


<style lang="scss" >
@import "@/assets/theme/_variables.scss";

.timeline {
  overflow-y: hidden;
  padding-bottom: 3rem !important;
  padding-top: 0 !important;
  position: relative;
  width: 80px;

  .pi {
    background-color: #fff;
    color: $primaryColor;
    font-size: 20px;
    font-weight: bold;
    margin-top: 1.5rem;
    padding: 6px;
    top: 10px;
    position: relative;
  }

  &.msb-timeline {
    .pi {
      color: #e18f39;
    }
  }

  &::before {
    background-color: $shade300;

    content: " ";
    height: 100%;
    margin-left: 14px;
    margin-top: 0;
    position: absolute;
    width: 4px;
  }

  .pi-circle.expired {
    color: #f3e213;
    cursor: pointer;
  }

  .pi-check-circle {
    color: #208423 !important;
    cursor: pointer;
  }
}

// .p-datatable-thead {
//   display: none;
// }

#payments-table {
  .p-tabview-panels {
    background-color: #fff !important;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  .p-highlight .p-tabview-nav-link {
    background-color: #fff !important;
    border: 1px solid #ddd;
    border-bottom: 1px solid #fff;
  }

  tr > td {
    border-bottom: none;
  }

  .payment-due-date-column {
    text-align: center;
    width: 120px;
  }
  .payment-note-column,
  .payment-file-column {
    text-align: center;
    width: 40px;

    .pi {
      color: $primaryColor;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .payment-title-column,
  .payment-note-column {
    min-width: 40%;
  }

  .chip-waiting {
    background-color: $shade600 !important;
  }
}

#payment-form {
  .p-dialog-content {
    min-width: 500px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .p-dialog-header {
    padding-bottom: 0;

    h3 {
      color: #333;
      font-weight: 700;
    }
  }

  label {
    color: $shade600;
    font-weight: 700;
    font-size: 0.8rem;
  }
}
</style>