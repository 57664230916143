<template>
  <div
    class="mb-2 grid align-items-center"
    v-for="bar in progress.series"
    :key="bar"
  >
    <div class="col-10">
      <strong v-if="showTitle">{{ bar.title }}</strong>
      <div class="mt-2 mb-2 progress-container">
        <div
          class="progress-color"
          :style="`width:${bar.percentage}%;background-color:${bar.color}`"
        ></div>
      </div>
    </div>
    <small class="label col-2">{{ bar.label }}</small>
  </div>
</template>

<script>
export default {
  props: {
    showTitle: {
      typeof: Boolean,
      default: true,
    },
    progress: {
      default: { series: [] },
      typeof: Object,
    },
  },
};
</script>

<style lang="sass" scoped>
.progress-container
  background-color: #ddd
  border-radius: 20px
  position: relative
  height: 10px
  widows: 100%

  .progress-color
    background-color: #fefefe
    border-radius: 20px
    height: 10px
    position: absolute
    widows: 100%

.label
  font-size: 1rem
  font-weight: bold
  text-align: center
</style>