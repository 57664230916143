<template>
  <div class="col-12">
    <Card>
      <template #header> </template>
      <template #title></template>

      <template #content>
        <form @submit.prevent="" class="mt-4">
          <div class="grid justify-content-center">
            <div class="col-4">
              <h4 class="mt-0">Dati cliente</h4>

              <FormInputText
                type="text"
                name="first_name"
                label="Nome *"
                :rules="form.customer.first_name.rules"
                :value="form.customer.first_name.value"
                :validationEnabled="validateForm"
                width="w-100"
                @update="updateCustomerFormInputText"
              />

              <FormInputText
                type="text"
                name="last_name"
                label="Cognome *"
                :rules="form.customer.last_name.rules"
                :value="form.customer.last_name.value"
                :validationEnabled="validateForm"
                width="w-100"
                @update="updateCustomerFormInputText"
              />

              <FormInputText
                type="text"
                name="email"
                label="Email *"
                :rules="form.customer.email.rules"
                :value="form.customer.email.value"
                :disabled="true"
                :validationEnabled="validateForm"
                width="w-100"
                @update="updateCustomerFormInputText"
              />

              <FormInputText
                type="text"
                name="phone_number"
                label="Telefono *"
                :rules="form.customer.phone_number.rules"
                :value="form.customer.phone_number.value"
                :validationEnabled="validateForm"
                width="w-100"
                @update="updateCustomerFormInputText"
              />
            </div>

            <div class="col-4 col-offset-1">
              <h4 class="mt-0">Dati fatturazione</h4>

              <FormInputText
                type="text"
                name="name"
                label="Nome Cognome / Ragione sociale"
                :rules="form.contact.name.rules"
                :value="form.contact.name.value"
                :validationEnabled="validateForm"
                width="w-100"
                @update="updateContactFormInputText"
              />

              <FormInputText
                type="text"
                name="address"
                label="Indirizzo"
                :rules="form.contact.address.rules"
                :value="form.contact.address.value"
                :validationEnabled="validateForm"
                width="w-100"
                @update="updateContactFormInputText"
              />

              <FormInputText
                type="text"
                name="vat"
                label="P.IVA"
                :rules="form.contact.vat.rules"
                :value="form.contact.vat.value"
                :validationEnabled="validateForm"
                width="w-100"
                @update="updateContactFormInputText"
              />

              <FormInputText
                type="text"
                name="fiscal_code"
                label="Codice Fiscale"
                :rules="form.contact.fiscal_code.rules"
                :value="form.contact.fiscal_code.value"
                :validationEnabled="validateForm"
                width="w-100"
                @update="updateContactFormInputText"
              />

              <FormInputText
                type="text"
                name="pec"
                label="PEC"
                :rules="form.contact.pec.rules"
                :value="form.contact.pec.value"
                :validationEnabled="validateForm"
                width="w-100"
                @update="updateContactFormInputText"
              />
            </div>

            <div class="col-9 mt-4 text-right">
              <Button type="submit" label="Salva" @click="submit" />
            </div>
          </div>
        </form>
      </template>
    </Card>

    <Card class="mt-5">
      <template #header> </template>
      <template #title></template>

      <template #content>
        <form @submit.prevent="" class="mt-4">
          <div class="grid justify-content-center">
            <div class="col-4">
              <div
                class="project-image w-100 mb-5"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/placeholder-proj.png') + ')',
                }"
              />
              <!-- <div
                class="project-image w-100 mb-5"
                :style="`background-image:url(${project.image_small_url})`"
              /> -->

              <FormInputText
                class="mt-2"
                type="text"
                name="name"
                label="Nome progetto *"
                :rules="form.name.rules"
                :value="form.name.value"
                :validationEnabled="validateProjectForm"
                width="w-100"
                @update="updateFormInputText"
              />

              <FormInputText
                class="mt-2"
                type="text"
                name="code"
                label="Codice commessa *"
                :rules="form.code.rules"
                :value="form.code.value"
                :validationEnabled="validateProjectForm"
                width="w-100"
                @update="updateFormInputText"
              />

              <!-- <FormInputText
                class="mt-2"
                type="text"
                name="address"
                label="Indirizzo"
                :rules="form.contact.address.rules"
                :value="form.contact.address.value"
                :validationEnabled="validateProjectForm"
                width="w-100"
                @update="updateFormInputText"
              /> -->

              <div class="p-field mt-2">
                <label class="w-100"> Descrizione </label>

                <div class="mt-1">
                  <Textarea
                    v-model="form.description.value"
                    rows="5"
                    class="w-100"
                  />
                </div>

                <small class="mt-1 mb-5 p-error`"></small>
              </div>

              <div class="p-field mt-4">
                <label class="w-100"> Inizio progetto </label>

                <Calendar
                  class="w-100 mt-1"
                  v-model="form.startDate"
                  :inline="false"
                  dateFormat="dd/mm/yy"
                />

                <small class="mt-1 mb-5 p-error`"></small>
              </div>

              <FormInputText
                class="mt-4"
                type="text"
                name="price"
                label="Importo *"
                :rules="form.price.rules"
                :value="form.price.value"
                :validationEnabled="validateProjectForm"
                width="w-100"
                @update="updateFormInputText"
              />

              <div class="mt-2">
                <label class="w-100"> Responsabile progetto *</label>

                <AutoComplete
                  v-model="form.teamMember"
                  :suggestions="teamMembers"
                  :dropdown="true"
                  :forceSelection="true"
                  @complete="searchProjectManager($event)"
                  field="full_name"
                  class="w-100 mt-1"
                />
              </div>
            </div>

            <div class="col-4 col-offset-1">
              <h4 class="mt-0">Dati catastali</h4>

              <FormInputText
                type="text"
                class="mt-2"
                name="section"
                label="Sezione"
                :rules="form.section.rules"
                :value="form.section.value"
                :validationEnabled="validateProjectForm"
                width="w-100"
                @update="updateFormInputText"
              />

              <FormInputText
                type="text"
                class="mt-2"
                name="sheet"
                label="Foglio"
                :rules="form.sheet.rules"
                :value="form.sheet.value"
                :validationEnabled="validateProjectForm"
                width="w-100"
                @update="updateFormInputText"
              />

              <FormInputText
                type="text"
                class="mt-2"
                name="cadastral_map"
                label="Mappa catastale"
                :rules="form.cadastral_map.rules"
                :value="form.cadastral_map.value"
                :validationEnabled="validateProjectForm"
                width="w-100"
                @update="updateFormInputText"
              />

              <FormInputText
                type="text"
                class="mt-2"
                name="subaltern"
                label="Subalterno"
                :rules="form.subaltern.rules"
                :value="form.subaltern.value"
                :validationEnabled="validateProjectForm"
                width="w-100"
                @update="updateFormInputText"
              />

              <h4 class="mt-4">Documenti preliminari</h4>
              <div class="mt-4">
                <label class="w-100 mr-2"> Preventivo / Offerta</label>

                <template
                  v-if="
                    project.offer_pdf_url !== null &&
                    project.offer_pdf_url !== ''
                  "
                >
                  <a :href="project.offer_pdf_url" target="_blank">
                    <i class="pi pi-file-pdf" />
                  </a>
                </template>

                <AutoComplete
                  :suggestions="dropboxFiles"
                  :dropdown="true"
                  :forceSelection="true"
                  @complete="searchDropboxFiles($event)"
                  :disabled="project.offer_pdf_approved !== null"
                  field="name"
                  class="w-100 mt-1"
                />
              </div>

              <div class="mt-4">
                <label class="w-100 mr-2"> Disciplinare d'incarico</label>
                <template
                  v-if="
                    project.disciplinary_pdf_url !== null &&
                    project.disciplinary_pdf_url !== ''
                  "
                >
                  <a :href="project.disciplinary_pdf_url" target="_blank">
                    <i class="pi pi-file-pdf" />
                  </a>
                </template>

                <AutoComplete
                  :suggestions="dropboxFiles"
                  :dropdown="true"
                  :forceSelection="true"
                  @complete="searchDropboxFiles($event)"
                  :disabled="project.disciplinary_pdf_approved !== null"
                  field="name"
                  class="w-100 mt-1"
                />
              </div>

              <div class="mt-4">
                <label class="w-100 mr-2"> Privacy</label>

                <template
                  v-if="
                    project.privacy_pdf_url !== null &&
                    project.privacy_pdf_url !== ''
                  "
                >
                  <a :href="project.privacy_pdf_url" target="_blank">
                    <i class="pi pi-file-pdf" />
                  </a>
                </template>

                <AutoComplete
                  :suggestions="dropboxFiles"
                  :dropdown="true"
                  :forceSelection="true"
                  @complete="searchDropboxFiles($event)"
                  :disabled="project.privacy_pdf_approved !== null"
                  field="name"
                  class="w-100 mt-1"
                />
              </div>

              <h4 class="mt-4">Dropbox</h4>
              <Button
                type="button"
                label="Vai alla cartella Dropbox"
                icon="pi pi-cloud"
                @click="openDropboxFolder"
              />
            </div>

            <div class="col-9 mt-4 text-right">
              <Button type="submit" label="Salva" @click="submitProject" />
            </div>
          </div>
        </form>
      </template>
    </Card>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";

import { ADMINS_ALL } from "@/store/admins.module";
import { CONTACTS_UPDATE } from "@/store/contacts.module";
import { USERS_UPDATE } from "@/store/users.module";
import { PROJECTS_UPDATE } from "@/store/projects.module";
import { TASKS_DOCUMENT_FILES } from "@/store/tasks.module";

export default {
  name: "InfoTab",

  components: {},

  emits: ["fetchProject"],

  props: {
    project: {
      required: true,
      typeof: Object,
    },
  },

  data() {
    return {
      loading: true,

      isFormVisible: false,

      /// Form
      validateForm: false,
      validateProjectForm: false,

      dropboxFiles: [],

      form: {
        customer: {
          first_name: {
            error: "",
            rules: { required: true },
            value: "",
          },

          last_name: {
            error: "",
            rules: { required: true },
            value: "",
          },

          email: {
            disabled: false,
            error: "",
            rules: { required: true },
            value: "",
          },

          phone_number: {
            error: "",
            rules: { required: true },
            value: "",
          },
        },

        contact: {
          address: {
            error: "",
            rules: {},
            value: "",
          },

          fiscal_code: {
            error: "",
            rules: {},
            value: "",
          },

          name: {
            disabled: false,
            error: "",
            rules: {},
            value: "",
          },

          pec: {
            error: "",
            rules: {},
            value: "",
          },

          vat: {
            error: "",
            rules: {},
            value: "",
          },
        },

        name: {
          error: "",
          rules: { required: true },
          value: "",
        },

        code: {
          error: "",
          rules: { required: true },
          value: "",
        },

        startDate: null,

        price: {
          error: "",
          rules: {},
          value: "",
        },

        description: {
          error: "",
          rules: {},
          value: "",
        },

        cadastral_map: {
          error: "",
          rules: {},
          value: "",
        },

        section: {
          error: "",
          rules: {},
          value: "",
        },

        sheet: {
          error: "",
          rules: {},
          value: "",
        },

        subaltern: {
          error: "",
          rules: {},
          value: "",
        },

        teamMember: null,

        offerPdf: null,
        offerPdfApproved: null,
        disciplinaryPdf: null,
        disciplinaryPdfApproved: null,
        privacyPdf: null,
        privacyPdfApproved: null,
      },

      teamMembers: [],
    };
  },

  computed: {
    allDropboxFiles() {
      return this.$store.state.tasks.allDocumentFiles;
    },
    allProjectManagers() {
      return this.$store.state.admins.all;
    },

    isCustomerFormValid() {
      return (
        this.form.customer.first_name.error === "" &&
        this.form.customer.last_name.error === "" &&
        this.form.customer.phone_number.error === ""
      );
    },

    isProjectFormValid() {
      return (
        this.form.name.error === "" &&
        this.form.code.error === "" &&
        this.form.teamMember !== null
      );
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    searchDropboxFiles(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.dropboxFiles = [...this.allDropboxFiles];
        } else {
          this.dropboxFiles = this.allDropboxFiles.filter((file) => {
            return file.name.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    searchProjectManager(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.teamMembers = [...this.allProjectManagers];
        } else {
          this.teamMembers = this.allProjectManagers.filter((pm) => {
            return pm.full_name
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },

    openDropboxFolder() {
      window.open(
        `https://www.dropbox.com/home/Applicazioni/MSB%20Gestionale/${this.project.dropbox_path}`,
        "_blank"
      );
    },

    initForm() {
      // Dati cliente
      this.form.customer.first_name.value = this.project.customer.first_name;
      this.form.customer.first_name.error = "";

      this.form.customer.last_name.value = this.project.customer.last_name;
      this.form.customer.last_name.error = "";

      this.form.customer.email.value = this.project.customer.email;
      this.form.customer.email.error = "";

      this.form.customer.phone_number.value =
        this.project.customer.phone_number;
      this.form.customer.phone_number.error = "";

      // Dati fatturazione
      this.form.contact.address.value = this.project.contact.address;
      this.form.contact.address.error = "";

      this.form.contact.fiscal_code.value = this.project.contact.fiscal_code;
      this.form.contact.fiscal_code.error = "";

      this.form.contact.name.value = this.project.contact.name;
      this.form.contact.name.error = "";

      this.form.contact.pec.value = this.project.contact.pec;
      this.form.contact.pec.error = "";

      this.form.contact.vat.value = this.project.contact.vat;
      this.form.contact.vat.error = "";

      // Progetto
      this.form.name.value = this.project.name;
      this.form.name.error = "";

      this.form.code.value = this.project.code;
      this.form.code.error = "";

      this.form.startDate =
        this.project.start_date !== null
          ? this.$moment(this.project.start_date).format("DD/MM/YYYY")
          : null;

      this.form.price.value = this.project.price;
      this.form.price.error = "";

      this.form.description.value = this.project.description;
      this.form.description.error = "";

      this.form.cadastral_map.value = this.project.cadastral_map;
      this.form.cadastral_map.error = "";

      this.form.section.value = this.project.section;
      this.form.section.error = "";

      this.form.sheet.value = this.project.sheet;
      this.form.sheet.error = "";

      this.form.subaltern.value = this.project.subaltern;
      this.form.subaltern.error = "";

      this.form.teamMember = this.project.team_member;

      this.form.offerPdf = this.project.offer_pdf_url;
      this.form.offerPdfApproved = this.project.offer_pdf_approved;

      this.form.disciplinaryPdf = this.project.disciplinary_pdf_url;
      this.form.disciplinaryPdfApproved =
        this.project.disciplinary_pdf_approved;

      this.form.privacyPdf = this.project.privacy_pdf_url;
      this.form.privacyPdfApproved = this.project.privacy_pdf_approved;
    },

    errorCallback(err) {
      this.$toast.add({
        detail: err[0],
        life: 3000,
        severity: "error",
        summary: "Errore",
      });
    },

    submitProject() {
      this.validateProjectForm = true;

      if (!this.isProjectFormValid) {
        return;
      }

      this.$store.dispatch(SHOW_LOADER);

      /// Update project
      const projectParams = {
        project: {
          name: this.form.name.value,
          code: this.form.code.value,
          start_date:
            this.form.startDate !== null
              ? this.$moment(this.form.startDate).format("YYYY-MM-DD")
              : null,
          price: this.form.price.value,
          description: this.form.description.value,
          cadastral_map: this.form.cadastral_map.value,
          section: this.form.section.value,
          sheet: this.form.sheet.value,
          subaltern: this.form.subaltern.value,
          team_id:
            this.form.teamMember !== null ? this.form.teamMember.id : null,
          dropbox_privacy_pdf:
            this.form.privacyPdf !== null ? this.form.privacyPdf.path : null,
          dropbox_offer_pdf:
            this.form.privacyPdf !== null ? this.form.offerPdf.path : null,
          dropbox_disciplinary_pdf:
            this.form.disciplinaryPdf !== null
              ? this.form.disciplinaryPdf.path
              : null,
        },
      };

      let formData = this.jsonToFormData(projectParams);
      this.$store
        .dispatch(PROJECTS_UPDATE, {
          params: formData,
          id: this.project.id,
        })
        .then(() => {
          this.successCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.isFormVisible = true;
          this.errorCallback(err);
        });
    },

    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },

    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },

    submit() {
      this.validateForm = true;

      if (!this.isCustomerFormValid) {
        return;
      }

      this.$store.dispatch(SHOW_LOADER);

      /// Update user
      const customerPparams = {
        user: {
          first_name: this.form.customer.first_name.value,
          last_name: this.form.customer.last_name.value,
          phone_number: this.form.customer.phone_number.value,
        },
      };

      this.$store
        .dispatch(USERS_UPDATE, {
          params: customerPparams,
          id: this.project.customer.id,
        })
        .then(() => {
          this.updateContact();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.errorCallback(err);
        });
    },

    updateContact() {
      /// Update contact
      const contactParams = {
        contact: {
          address: this.form.contact.address.value,
          fiscal_code: this.form.contact.fiscal_code.value,
          name: this.form.contact.name.value,
          pec: this.form.contact.pec.value,
          vat: this.form.contact.vat.value,
        },
      };

      this.$store
        .dispatch(CONTACTS_UPDATE, {
          params: contactParams,
          id: this.project.contact.id,
        })
        .then(() => {
          this.successCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.validateForm = false;
          this.errorCallback(err);
        });
    },

    fetchProjectManagers() {
      this.$store.dispatch(ADMINS_ALL);
    },

    fetchDropboxFiles() {
      this.$store.dispatch(SHOW_LOADER);

      this.$store
        .dispatch(TASKS_DOCUMENT_FILES, { projectId: this.project.id })
        .finally(() => {
          this.$store.dispatch(HIDE_LOADER);
          this.loading = false;
        });
    },

    successCallback() {
      this.validateForm = false;
      this.validateProjectForm = false;

      this.$store.dispatch(HIDE_LOADER);
      this.$emit("fetchProject");

      this.$toast.add({
        detail: "Dati cliente e di fatturazione salvati correttamente",
        life: 3000,
        severity: "success",
        summary: "Commessa",
      });
    },

    updateCustomerFormInputText({ error, name, value }) {
      this.form.customer[`${name}`].error = error;
      this.form.customer[`${name}`].value = value;
    },

    updateContactFormInputText({ error, name, value }) {
      this.form.contact[`${name}`].error = error;
      this.form.contact[`${name}`].value = value;
    },

    updateFormInputText({ error, name, value }) {
      this.form[`${name}`].error = error;
      this.form[`${name}`].value = value;
    },
  },

  mounted() {
    this.fetchDropboxFiles();
    this.fetchProjectManagers();
    this.initForm();
  },
};
</script>


<style lang="scss" >
@import "@/assets/theme/_variables.scss";

.timeline {
  overflow-y: hidden;
  padding-bottom: 3rem !important;
  padding-top: 0 !important;
  position: relative;
  width: 80px;

  .pi {
    background-color: #fff;
    color: $primaryColor;
    font-size: 20px;
    font-weight: bold;
    margin-top: 1.5rem;
    padding: 6px;
    top: 10px;
    position: relative;
  }

  &.msb-timeline {
    .pi {
      color: #e18f39;
    }
  }

  &::before {
    background-color: $shade300;

    content: " ";
    height: 100%;
    margin-left: 14px;
    margin-top: 0;
    position: absolute;
    width: 4px;
  }

  .pi-circle.expired {
    color: #f3e213;
    cursor: pointer;
  }

  .pi-check-circle {
    color: #208423 !important;
    cursor: pointer;
  }
}

// .p-datatable-thead {
//   display: none;
// }

#payments-table {
  .p-tabview-panels {
    background-color: #fff !important;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  .p-highlight .p-tabview-nav-link {
    background-color: #fff !important;
    border: 1px solid #ddd;
    border-bottom: 1px solid #fff;
  }

  tr > td {
    border-bottom: none;
  }

  .payment-due-date-column {
    text-align: center;
    width: 120px;
  }
  .payment-note-column,
  .payment-file-column {
    text-align: center;
    width: 40px;

    .pi {
      color: $primaryColor;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .payment-title-column,
  .payment-note-column {
    min-width: 40%;
  }

  .chip-waiting {
    background-color: $shade600 !important;
  }
}

#payment-form {
  .p-dialog-content {
    min-width: 500px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .p-dialog-header {
    padding-bottom: 0;

    h3 {
      color: #333;
      font-weight: 700;
    }
  }

  label {
    color: $shade600;
    font-weight: 700;
    font-size: 0.8rem;
  }
}
</style>