import ProjectsService from '../services/projects.service';

// action types
export const PROJECTS_ALL = "projects/fetchAll";
export const PROJECTS_ARCHIVED = "projects/fetchArchived";
export const PROJECTS_UPDATE = "projects/updateProject";
export const PROJECTS_CREATE = "projects/createProject";
export const PROJECTS_DELETE = "projects/deleteProject";
export const PROJECT_DETAIL = "projects/projectDetail";

export const PROJECT_DOCUMENTS = "projects/fetchDocuments";


export const projects = {
  namespaced: true,

  state: {
    all: [],
    archived: [],
    selectedProject: null,

    documentFolderDetails: []
  },

  actions: {
    fetchAll(ctx) {
      ProjectsService.activeProjects().then(
        response => {
          ctx.commit('setProjects', response.data.projects);
          return Promise.resolve(response.data.projects);
        },
        error => {
          ctx.commit('setProjects', []);
          return Promise.reject(error);
        });
    },

    fetchArchived(ctx) {
      ProjectsService.archivedProjects().then(
        response => {
          ctx.commit('setArchivedProjects', response.data.projects);
          return Promise.resolve(response.data.projects);
        },
        error => {
          ctx.commit('setArchivedProjects', []);
          return Promise.reject(error);
        });
    },

    createProject(ctx, params) {
      return ProjectsService.createProject(params).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    updateProject(ctx, { params, id }) {
      console.log(id);

      return ProjectsService.updateProject(id, params).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    deleteProject(ctx, id) {
      return ProjectsService.deleteProject(id).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    archiveProject(ctx, id) {
      return ProjectsService.archiveProject(id).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    unarchiveProject(ctx, id) {
      return ProjectsService.unarchiveProject(id).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    projectDetail(ctx, id) {
      return ProjectsService.projectDetail(id).then(
        response => {
          ctx.commit('setSelectedProject', response.data.project);
          return Promise.resolve(response.data.project);
        },
        error => {
          ctx.commit('setSelectedProject', null);
          return Promise.reject(error);
        }
      );
    },


    /// Documents
    fetchDocuments(ctx, { projectId, path }) {
      return ProjectsService.projectDocumentsFilder(projectId, path).then(
        response => {
          ctx.commit('setDocumentFolderDetails', response.data.files);
          return Promise.resolve(response.data.files);
        },
        error => {
          ctx.commit('setDocumentFolderDetails', []);
          return Promise.reject(error);
        }
      );
    }
  },

  getters: {
  },

  mutations: {
    setProjects(state, projects) {
      state.all = projects;
    },

    setArchivedProjects(state, projects) {
      state.archived = projects;
    },

    setSelectedProject(state, project) {
      state.selectedProject = project;
    },

    /// Documents
    setDocumentFolderDetails(state, files) {
      state.setDocumentFolderDetails = files;
    },
  }
};