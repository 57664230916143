<template>
  <Card class="user-card" @click="goToProjectDetail">
    <!-- <template #header>
      
    </template> -->
    <template #title>
      <div class="text-center">
        <h5 class="user-name mb-3 mt-0">
          {{
            project.customer !== null
              ? project.customer.full_name
              : "Nessun referente"
          }}
        </h5>
        <h4 class="project-name mb-5 mt-0">
          {{ project.name }}
        </h4>
      </div>
    </template>
    <template #content>
      <Progress :progress="projectProgress" />
      <Progress :progress="paymentProgress" />

      <div
        class="project-image w-90 m-auto mt-4"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/placeholder-proj.png') + ')',
        }"
      />
      <!-- <div
        class="project-image w-90 m-auto mt-4"
        :style="`background-image:url(${project.image_small_url})`"
      /> -->

      <div class="grid align-items-center mt-4">
        <div class="col-6 mb-3">
          <p class="mb-0 mt-1">
            <label class="mr-2">Codice commessa</label>
          </p>
          <p class="mb-0 mt-1">{{ project.code }}</p>
        </div>

        <div class="col-6 mb-3">
          <p class="mb-0 mt-1">
            <label class="mr-2">Responsabile progetto </label>
          </p>
          <p class="mb-0 mt-1">
            {{
              project.team_member !== null ? project.team_member.full_name : "-"
            }}
          </p>
        </div>
      </div>
      <!-- <div class="mt-3">
        <p class="mb-0 mt-1">
          <label class="mr-2">Telefono</label>
        </p>
        <p class="mb-0 mt-1">{{ user.phone_number || "-" }}</p>
      </div>

      <div class="mt-3">
        <p class="mb-0 mt-1">
          <label class="mr-2">Responsabile di progetto</label>
        </p>
        <p class="mb-0 mt-1">{{ user.projects_counter }} progetti</p>
      </div> -->
    </template>
  </Card>
</template>


<script>
import Card from "primevue/card";
import Progress from "@/components/projects/Progress.vue";

export default {
  components: {
    Card,
    Progress,
  },

  data() {
    return {
      paymentProgress: {
        series: [
          {
            percentage: this.project.payments_data.to_pay_percentage,
            color: "#F3E213",
            label: `${this.project.payments_data.to_pay_percentage}%`,
            title: "Fatture in attesa di pagamento",
          },
          {
            percentage: this.project.payments_data.payed_percentage,
            color: "#208423",
            label: `${this.project.payments_data.payed_percentage}%`,
            title: "Fatture pagate",
          },
        ],
      },
      projectProgress: {
        series: [
          {
            percentage: this.project.percentage_tasks,
            color: "#3CA3FF",
            label: `${this.project.percentage_tasks}%`,
            title: "Avanzamento progetto",
          },
        ],
      },
    };
  },

  methods: {
    deleteItem(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Confermi l'eliminazione del progetto?",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Sì",
        accept: () => {
          this.$emit("deleteItem");
        },
        reject: () => {
          // nothing to do here
        },
      });
    },

    goToProjectDetail() {
      /// --> Project detail
      this.$router.push({
        name: "projectDetail",
        params: { id: this.project.id },
      });
    },

    openEditForm() {
      this.$emit("openEditForm");
    },
  },

  name: "ProjectCard",

  props: {
    project: {
      require: true,
      typeof: Object,
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/theme/_variables.scss"

.user-name
  font-weight: normal !important

.project-image
  background-color: #ddd
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  border-radius: 8px
  content: ""
  height: 200px
  width: 100%
</style>