
import { createStore } from 'vuex'

import { admins } from "./admins.module";
import { auth } from "./auth.module";
import { contacts } from "./contacts.module";
import { htmlClass } from "./html_class.module";
import { users } from "./users.module";
import { payments } from "./payments.module";
import { projects } from "./projects.module";
import { tasks } from "./tasks.module";


const store = createStore({
  state: {
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    admins,
    auth,
    contacts,
    htmlClass,
    payments,
    projects,
    users,
    tasks
  }
})

export default store;