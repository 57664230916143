import ContactsService from '../services/contacts.service';

// action types
export const CONTACTS_UPDATE = "contacts/updateUser";

export const contacts = {
  namespaced: true,

  state: {},

  actions: {
    updateUser(ctx, { params, id }) {

      return ContactsService.updateContact(id, params).then(
        contact => {
          return Promise.resolve(contact);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },


  },

  getters: {
  },

  mutations: {

  }
};