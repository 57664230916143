<template>
  <Card class="user-card">
    <!-- <template #header>
      
    </template> -->
    <template #title>
      <div class="text-right">
        <Button
          icon="pi pi-pencil"
          class="
            edit-btn
            p-button
            p-component
            p-button-icon-only
            p-button-rounded
            p-button-text
            mr-2
          "
          @click="openEditForm"
        />

        <Button
          icon="pi pi-trash"
          class="
            delete-btn
            p-button
            p-component
            p-button-icon-only
            p-button-rounded
            p-button-danger
            p-button-text
          "
          @click="deleteItem($event)"
          v-if="user.id !== currentUser.id"
        />
      </div>

      <!-- <div class="text-center mb-3" v-if="user.avatar">
        <Avatar :src="user.avatar" shape="circle" size="xlarge" />
      </div> -->
      <h5 class="mb-3 mt-0">{{ user.fullname }}</h5>
    </template>
    <template #content>
      <!-- <div class="mb-3">
        <p class="mb-0 mt-1">
          <label class="mr-2">Progetto</label>
        </p>
        <p class="mb-0 mt-1">{{ user.projectName }}</p>
      </div> -->
      <div class="mb-3">
        <div class="mb-0 mt-1">
          <h5 class="mb-0 mr-2">{{ user.full_name }}</h5>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0 mt-1">
          <label class="mr-2">Email</label>
        </p>
        <p class="mb-0 mt-1">{{ user.email }}</p>
      </div>
      <div class="mt-3">
        <p class="mb-0 mt-1">
          <label class="mr-2">Telefono</label>
        </p>
        <p class="mb-0 mt-1">{{ user.phone_number || "-" }}</p>
      </div>

      <!-- <div class="mt-3">
        <p class="mb-0 mt-1">
          <label class="mr-2">Responsabile di progetto</label>
        </p>
        <p class="mb-0 mt-1">{{ user.projects_counter }} progetti</p>
      </div> -->
    </template>
  </Card>
</template>


<script>
// import Avatar from "primevue/avatar";
import Card from "primevue/card";

export default {
  components: {
    // Avatar,
    Card,
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
  },

  methods: {
    deleteItem(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Confermi l'eliminazione dell'utente?",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Sì",
        accept: () => {
          this.$emit("deleteItem");
        },
        reject: () => {
          // nothing to do here
        },
      });
    },

    openEditForm() {
      this.$emit("openEditForm");
    },
  },

  name: "UserCard",

  props: {
    avatar: String,
    user: {
      require: true,
      typeof: Object,
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/theme/_variables.scss"

.p-confirm-popup
  .p-d-flex
    align-content: center
    align-items: center
    display: flex
    flex-direction: row
    padding: 10px

    > *
      padding: 0 5px

    .pi
      color: $dangerButtonBg

.p-confirm-popup-footer
  .p-button.p-button-sm
    font-size: 1rem
</style>