<template>
  <div id="users-view">
    <div class="header">
      <div class="container align-content-start">
        <div class="col-12">
          <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" />
        </div>
      </div>
    </div>

    <div class="content col-12">
      <div class="container grid align-content-start">
        <div class="col-12">
          <Toolbar>
            <template #start></template>

            <template #end>
              <Button
                label="Aggiungi utente"
                class="p-button-rounded p-button-sm"
                icon="pi pi-plus"
                @click="openForm"
              />
            </template>
          </Toolbar>
        </div>

        <h4 class="col-12 text-center mt-5" v-if="users.length == 0">
          Nessun utente creato
        </h4>

        <div class="col-3" v-for="user in users" :key="user.id" v-else>
          <UserCard
            :user="user"
            @openEditForm="openForm(user)"
            @deleteItem="deleteUser(user)"
          />
        </div>
      </div>
    </div>
  </div>

  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-d-flex p-p-4">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
        <p class="p-pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>

  <Dialog
    id="user-form"
    v-model:visible="isFormVisible"
    :modal="true"
    :closable="true"
    :closeOnEscape="false"
    :showHeader="true"
  >
    <template #header>
      <h3>{{ user.id != null ? "Modifica utente" : "Nuovo utente" }}</h3>
    </template>

    <div class="p-fluid">
      <form class="mt-4" @submit.prevent="submit">
        <FormInputText
          type="text"
          name="first_name"
          label="Nome *"
          :rules="form.first_name.rules"
          :value="form.first_name.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <FormInputText
          type="text"
          name="last_name"
          label="Cognome *"
          :rules="form.last_name.rules"
          :value="form.last_name.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <FormInputText
          type="email"
          name="email"
          label="E-mail *"
          :rules="form.email.rules"
          :value="form.email.value"
          :validationEnabled="validateForm"
          :disabled="form.email.disabled"
          @update="updateFormInputText"
        />

        <FormInputText
          type="text"
          name="phone_number"
          label="Telefono *"
          :rules="form.phone_number.rules"
          :value="form.phone_number.value"
          :validationEnabled="validateForm"
          :disabled="form.phone_number.disabled"
          @update="updateFormInputText"
        />
      </form>
    </div>

    <template #footer>
      <div class="mt-4 text-right">
        <Button type="submit" label="Salva" @click="submit" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";
import {
  USERS_ALL,
  USERS_CREATE,
  USERS_UPDATE,
  USERS_DELETE,
} from "@/store/users.module";
import UserCard from "@/components/users/UserCard";

export default {
  data() {
    return {
      breadcrumb: {
        home: { icon: "pi pi-home", to: "/" },
        items: [{ label: "Utenti" }],
      },

      isFormVisible: false,
      user: null,

      /// Form
      validateForm: false,
      form: {
        first_name: {
          error: "",
          rules: { required: true },
          value: "",
        },

        last_name: {
          error: "",
          rules: { required: true },
          value: "",
        },

        email: {
          disabled: false,
          error: "",
          rules: { required: true },
          value: "",
        },

        phone_number: {
          error: "",
          rules: { required: true },
          value: "",
        },
      },
    };
  },

  components: {
    UserCard,
  },

  computed: {
    users() {
      return this.$store.state.users.all;
    },

    isFormValid() {
      return (
        this.form.first_name.error === "" &&
        this.form.last_name.error === "" &&
        this.form.phone_number.error === "" &&
        this.form.email.error === ""
      );
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    cleanUpForm() {
      this.form.first_name.value = "";
      this.form.first_name.errors = "";

      this.form.last_name.errors = "";
      this.form.last_name.value = "";

      this.form.email.value = "";
      this.form.email.errors = "";

      this.form.phone_number.value = "";
      this.form.phone_number.errors = "";
    },

    deleteUser(selectedUser) {
      this.$store
        .dispatch(USERS_DELETE, selectedUser.id)
        .then(() => {
          this.successDeleteCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.errorCallback(err);
        });
    },

    openForm(selectedUser) {
      this.cleanUpForm();

      this.user = selectedUser;
      this.form.first_name.value = selectedUser.first_name;
      this.form.last_name.value = selectedUser.last_name;
      this.form.email.value = selectedUser.email;
      this.form.phone_number.value = selectedUser.phone_number;
      this.form.email.disabled = selectedUser.id != null;

      this.isFormVisible = true;
    },

    updateFormInputText({ error, name, value }) {
      this.form[`${name}`].error = error;
      this.form[`${name}`].value = value;
    },

    submit() {
      this.validateForm = true;

      if (!this.isFormValid) {
        return;
      }

      this.$store.dispatch(SHOW_LOADER);
      this.isFormVisible = false;

      if (this.user.id != null) {
        /// Update
        const params = {
          user: {
            first_name: this.form.first_name.value,
            last_name: this.form.last_name.value,
            phone_number: this.form.phone_number.value,
          },
        };

        this.$store
          .dispatch(USERS_UPDATE, { params: params, id: this.user.id })
          .then(() => {
            this.successCallback();
          })
          .catch((err) => {
            this.$store.dispatch(HIDE_LOADER);
            this.isFormVisible = true;
            this.errorCallback(err);
          });
      } else {
        const params = {
          user: {
            role: "customer",
            first_name: this.form.first_name.value,
            last_name: this.form.last_name.value,
            email: this.form.email.value,
            phone_number: this.form.phone_number.value,
          },
        };

        /// Create
        this.$store
          .dispatch(USERS_CREATE, params)
          .then(() => {
            this.successCallback();
          })
          .catch((err) => {
            this.$store.dispatch(HIDE_LOADER);
            this.isFormVisible = true;
            this.errorCallback(err);
          });
      }
    },

    fetchAll() {
      this.$store.dispatch(USERS_ALL);
    },

    successDeleteCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchAll();

      this.$toast.add({
        detail: "Utente eliminato correttamente",
        life: 3000,
        severity: "success",
        summary: "Team",
      });
    },

    successCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchAll();

      this.$toast.add({
        detail: "Utente salvato correttamente",
        life: 3000,
        severity: "success",
        summary: "Team",
      });
    },

    errorCallback(err) {
      this.$toast.add({
        detail: err[0],
        life: 3000,
        severity: "error",
        summary: "Errore",
      });
    },
  },

  mounted() {
    this.fetchAll();
  },

  name: "UsersView",
};
</script>