import ApiService from './api.service';

class UsersService {
  async allUsers() {
    return await ApiService.get('/users', 'role=user');
  }

  async createUser(params) {
    return await ApiService.post('/users', params);
  }

  async updateUser(id, params) {
    return await ApiService.put(`/users/${id}`, params);
  }

  async deleteUser(id) {
    return await ApiService.delete(`/users/${id}`);
  }
}

export default new UsersService();
