import ApiService from './api.service';

class ProjectsService {
  async activeProjects() {
    return await ApiService.get('/projects?status=active');
  }

  async archivedProjects() {
    return await ApiService.get('/projects?status=archived');
  }

  async createProject(params) {
    return await ApiService.post('/projects', params);
  }

  async updateProject(id, params) {
    return await ApiService.postMultipart(`/projects/${id}/update`, params);
  }

  async deleteProject(id) {
    return await ApiService.delete(`/projects/${id}`);
  }

  async archiveProject(id) {
    return await ApiService.post(`/projects/${id}/archive`);
  }

  async unarchiveProject(id) {
    return await ApiService.post(`/projects/${id}/unarchive`);
  }

  async projectDetail(id) {
    return await ApiService.get(`/projects/${id}`);
  }

  async projectDocumentsFilder(id, path) {
    return await ApiService.post(`/projects/${id}/folders`, { path: path });
  }
}

export default new ProjectsService();
