<template>
  <div v-if="!loading">
    <div class="header">
      <div class="container align-content-start">
        <div class="col-12">
          <Toolbar class="pl-0 pr-0">
            <template #start>
              <Breadcrumb
                :home="breadcrumb.home"
                :model="breadcrumb.items"
                class="pl-0"
              />
            </template>

            <template #end>
              <Button
                label="Archivia Progetto"
                class="p-button-rounded p-button-sm"
                icon="pi pi-download"
                @click="archiveProject"
              />
            </template>
          </Toolbar>
        </div>
      </div>
    </div>

    <div class="content col-12">
      <div class="container grid align-content-start">
        <TabView
          class="col-12"
          :activeIndex="tabActiveIndex"
          @tab-click="tabClick($event)"
          @tab-change="tabChange($event)"
          lazy
        >
          <TabPanel header="Info commessa">
            <InfoTab :project="project" />
          </TabPanel>
          <TabPanel header="Attività" class="text-left">
            <TasksTab :project="project" />
          </TabPanel>
          <TabPanel header="Documenti">
            <DocumentsTab :project="project" />
          </TabPanel>
          <TabPanel header="Pagamenti">
            <PaymentsTab :project="project" @fetchProject="fetchProject" />
          </TabPanel>
          <TabPanel header="Notifiche"> </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";
import { PROJECT_DETAIL } from "../../store/projects.module";
import DocumentsTab from "@/components/projects/tabs/DocumentsTab.vue";
import InfoTab from "@/components/projects/tabs/InfoTab.vue";
import TasksTab from "@/components/projects/tabs/TasksTab.vue";
import PaymentsTab from "@/components/projects/tabs/PaymentsTab.vue";

export default {
  components: {
    DocumentsTab,
    InfoTab,
    PaymentsTab,
    TasksTab,
  },

  data() {
    const route = this.$router.currentRoute.value;

    return {
      breadcrumb: {
        home: { icon: "pi pi-home", to: "/" },
        items: [{ label: "Progetti", to: "/projects" }, { label: "" }],
      },
      loading: true,
      id: route.params.id,

      tabActiveIndex: 0,

      /// Documents
      files: [],

      // /// Attività
      // tasks: [
      //   {
      //     milestone: false,

      //     completed: true,
      //     status: "Ordered",
      //     date: "15/10/2020 10:30",
      //     icon: "pi pi-shopping-cart",
      //     color: "#9C27B0",
      //     image: "game-controller.jpg",
      //   },
      //   {
      //     milestone: true,

      //     completed: true,
      //     status: "Processing",
      //     date: "15/10/2020 14:00",
      //     icon: "pi pi-cog",
      //     color: "#673AB7",
      //   },
      //   {
      //     milestone: false,

      //     completed: false,
      //     status: "Shipped",
      //     date: "15/10/2020 16:15",
      //     icon: "pi pi-shopping-cart",
      //     color: "#FF9800",
      //   },
      //   {
      //     milestone: false,

      //     completed: false,
      //     status: "Delivered",
      //     date: "16/10/2020 10:00",
      //     icon: "pi pi-check",
      //     color: "#607D8B",
      //   },
      // ],
    };
  },

  computed: {
    project() {
      return this.$store.state.projects.selectedProject;
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    archiveProject() {
      console.log("[TODO] archiveProject");
    },

    tabChange(e) {
      this.tabActiveIndex = e.index;
    },

    tabClick(e) {
      this.tabActiveIndex = e.index;

      // switch (this.tabActiveIndex) {
      //   case 0:
      //     break;

      //   case 2: // Documents
      //     // Lo fa il component
      //     break;

      //   default:
      //     break;
      // }
    },

    fetchProject() {
      this.$store.dispatch(SHOW_LOADER);

      const self = this;

      this.$store
        .dispatch(PROJECT_DETAIL, this.id)
        .then((proj) => {
          self.breadcrumb.items.at(-1)["label"] = proj.name;
          self.loading = false;
        })
        .catch((e) => {
          console.log(`[ERRORE] ${e}`);

          /// --> Projects
          this.$router.push({
            name: "projects",
          });
        })
        .finally(() => {
          this.$store.dispatch(HIDE_LOADER);
        });
    },

    /// Timeline
    timelineTaskCheck(task) {
      console.log(task);
    },
    timelineTaskUncheck(task) {
      console.log(task);
    },
  },

  mounted() {
    this.fetchProject();

    const anchor = this.$router.currentRoute.value.hash;

    if (anchor === "#attivita") {
      this.tabActiveIndex = 1;
    } else if (anchor === "#documenti") {
      this.tabActiveIndex = 2;
    } else if (anchor === "#pagamenti") {
      this.tabActiveIndex = 3;
    } else if (anchor === "#notifiche") {
      this.tabActiveIndex = 4;
    } else {
      this.tabActiveIndex = 0;
    }
  },
};
</script>

