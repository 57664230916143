import { auth } from "@/store/auth.module";
import { createRouter, createWebHistory } from 'vue-router'

import DashBoardView from '@/views/dashboard/Dashboard.vue';
import TeamView from '@/views/admins/Team.vue';
import UsersView from '@/views/users/Users.vue';
import ProjectsView from '@/views/projects/Projects.vue';
import ProjectDetailView from '@/views/projects/ProjectDetail.vue';

const routes = [
  /// Guest
  {
    component: () => import('@/views/auth/Login.vue'),
    meta: { layout: 'AuthLayout', guest: true },
    name: 'login',
    path: '/login',
  },

  /// Logged-in
  {
    component: DashBoardView,
    meta: { requiresAuth: true, },
    path: '/',
  },
  {
    component: DashBoardView,
    meta: { requiresAuth: true, },
    path: '/dashboard',
    name: 'dashboard',
  },
  {
    component: UsersView,
    meta: { requiresAuth: true, },
    path: '/users',
    name: 'users',
  },
  {
    component: TeamView,
    meta: { requiresAuth: true, },
    path: '/team',
    name: 'team',
  },
  {
    component: ProjectsView,
    meta: { requiresAuth: true, },
    path: '/projects',
    name: 'projects',
  },
  {
    component: ProjectDetailView,
    meta: { requiresAuth: true, },
    path: '/projects/:id',
    name: 'projectDetail',
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const authStore = auth;
router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Auth required
    if (authStore.getters.isLoggedIn(authStore.state)) {
      next()
      return
    }
    next({ name: 'login' });
  } else {
    //
    if (to.matched.some((record) => record.meta.guest)) {
      if (authStore.getters.isLoggedIn(authStore.state)) {
        next({ name: 'dashboard' });
        return
      }
    }
    next()
  }
})


export default router