<template>
  <div :class="`p-field ${inputSize == 'large' ? 'p-inputtext-lg' : ''}`">
    <label class="w-100">
      {{ label }}
    </label>
    <span class="p-input-icon-right mt-1 w-100">
      <i class="pi pi-lock" v-if="disabled" />
      <i class="pi pi-euro" v-if="name === 'price'" />
      <InputText
        ref="input"
        :name="name"
        :type="type"
        :disabled="disabled"
        :value="value"
        :class="width"
        @change="change"
        @input="change"
      />
    </span>

    <small
      :class="`mt-1 ${inputSize == 'large' ? 'mb-5' : 'mb-3'} p-error`"
      v-html="`${error !== '' ? error : '&nbsp;'}`"
      v-if="validationEnabled"
    />
    <small
      :class="`mt-1 ${inputSize == 'large' ? 'mb-5' : 'mb-3'} p-error`"
      v-else
      >&nbsp;</small
    >
  </div>
</template>


<script>
export default {
  name: "FormInputField",

  props: {
    width: {
      default: "w-50",
      typeof: String,
    },
    inputSize: {
      default: "default",
      typeof: String,
    },

    disabled: {
      default: false,
      typeof: Boolean,
    },

    name: {
      required: true,
      typeof: String,
    },

    label: {
      required: true,
      typeof: String,
    },

    rules: {
      required: true,
      typeof: Object,
    },

    type: {
      default: "text",
      typeof: String,
    },

    validationEnabled: {
      default: false,
      typeof: Boolean,
    },

    value: {
      typeof: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  // mounted() {
  //   // Aggiungo delay per togliere il fastidioso messaggio di erroreal caricamento
  //   setTimeout(() => {
  //     this.isMounted = true;
  //     this.$refs.input.$el.change;
  //   }, 500);
  // },

  computed: {
    error() {
      if (
        this.$props.rules.required &&
        this.$props.value != undefined &&
        this.$props.value.length === 0
      ) {
        return "Campo obbligatorio";
      }

      if (
        this.$props.rules.min &&
        this.$props.value != undefined &&
        this.$props.value.length < this.$props.rules.min
      ) {
        return `Inserisci almeno ${this.$props.rules.min} caratteri`;
      }

      return "";
    },

    // errorText() {
    //   if (this.error === "") {
    //     return "&nbsp;";
    //   }
    //   return this.error;
    // },
  },

  // watch: {
  //   validationEnabled: function () {
  //     this.validate === true;
  //     console.log(`[VALIDATE]: ${this.validate}`);
  //   },
  // },

  methods: {
    change($event) {
      this.$emit("update", {
        error: this.error,
        name: this.$props.name,
        value: $event.target.value,
      });
    },
  },
};
</script>

<style>
.p-error {
  animation: fade 300ms;
  -webkit-animation: fade 300ms;
  -moz-animation: fade 300ms;
  -o-animation: fade 300ms;
  -ms-animation: fade 300ms;
}
</style>